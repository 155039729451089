.navigation-links2-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-links2-link {
  transition: 0.3s;
  margin-left: 0px;
  text-decoration: none;
}
.navigation-links2-link:hover {
  color: rgba(255, 255, 255, 0.8);
}
.navigation-links2-link1 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links2-link1:hover {
  color: rgba(255, 255, 255, 0.8);
}
.navigation-links2-link2 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links2-link2:hover {
  color: rgba(255, 255, 255, 0.8);
}











@media(max-width: 767px) {
  .navigation-links2-nav {
    align-items: center;
    flex-direction: column;
  }
  .navigation-links2-link {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links2-link1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links2-link2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links2-root-class-name18 {
    align-self: center;
  }
}
