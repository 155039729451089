.onepager-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(135deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.onepager-esileht {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.onepager-header {
  top: var(--dl-space-space-twounits);
  left: 0px;
  right: 0px;
  width: 100%;
  height: 84px;
  display: flex;
  z-index: 1;
  position: fixed;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.6);
  margin-top: auto;
  align-items: center;
  margin-left: auto;
  padding-top: 0px;
  margin-right: auto;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 10px;
  margin-bottom: auto;
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  backdrop-filter: blur(9px);
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(9px);
}
.onepager-navlink {
  display: contents;
}
.onepager-image {
  height: 2rem;
  padding: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.onepager-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.onepager-burger-menu {
  display: none;
}
.onepager-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: flex;
}
.onepager-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  align-items: center;
  backdrop-filer: blur(10px);
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  -webkit-backdrop-filter: blur(10px);
}
.onepager-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.onepager-image1 {
  width: 200px;
  object-fit: cover;
}
.onepager-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.onepager-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.onepager-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.onepager-container02 {
  width: 95%;
  height: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.onepager-container03 {
  top: 35vh;
  flex: 0 0 auto;
  left: 0px;
  width: auto;
  height: 175px;
  display: flex;
  position: absolute;
  margin-top: 40px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.onepager-text {
  font-size: 48px;
  min-width: 650px;
  align-self: flex-start;
}
.onepager-container04 {
  flex: 0 0 auto;
  width: 235px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.onepager-link {
  width: auto;
  height: 40px;
  transition: 0.2s;
  border-color: rgba(0, 0, 0, 0.6);
  border-width: 2px;
  border-radius: 20px;
  text-decoration: none;
  background-color: transparent;
}
.onepager-link:hover {
  border: none;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.6);
  border-style: solid;
  border-width: 2px;
  background-color: rgba(255, 255, 255, 0.1);
}
.onepager-link1 {
  width: auto;
  height: 40px;
  transition: 0.2s;
  border-color: rgba(0, 0, 0, 0.6);
  border-width: 2px;
  border-radius: 20px;
  text-decoration: none;
  background-color: transparent;
}
.onepager-link1:hover {
  left: 270px;
  border: none;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.6);
  border-style: solid;
  border-width: 2px;
  background-color: rgba(255, 255, 255, 0.1);
}
.onepager-image2 {
  top: 35vh;
  right: 10%;
  width: auto;
  bottom: 0px;
  height: 279px;
  position: absolute;
  object-fit: cover;
  margin-right: 0px;
}
.onepager-container05 {
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-self: center;
  margin-top: 30vh;
  align-items: center;
  justify-content: space-between;
}
.onepager-teenused {
  width: 100%;
  height: auto;
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.onepager-container06 {
  width: 95%;
  height: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.onepager-text10 {
  font-size: 48px;
  margin-top: 150px;
  margin-bottom: var(--dl-space-space-twounits);
}
.onepager-printimine {
  flex: 0 0 auto;
  width: 100%;
  height: 250px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  transition: 0.2s;
  align-items: center;
  border-radius: 10px;
}
.onepager-printimine:hover {
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
.onepager-image3 {
  width: auto;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.onepager-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 560px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.onepager-text13 {
  font-size: 36px;
}
.onepager-text14 {
  font-size: 24px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
}
.onepager-mudelleerimine {
  flex: 0 0 auto;
  width: 100%;
  height: 250px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-twounits);
  transition: 0.2s;
  align-items: center;
  border-radius: 10px;
  margin-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.onepager-mudelleerimine:hover {
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
.onepager-image4 {
  width: auto;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.onepager-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 560px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.onepager-text15 {
  font-size: 36px;
}
.onepager-text16 {
  font-size: 24px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
}
.onepager-prototpimine {
  flex: 0 0 auto;
  width: 100%;
  height: 250px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  transition: 0.2s;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
}
.onepager-prototpimine:hover {
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
.onepager-image5 {
  width: auto;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.onepager-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 560px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.onepager-text17 {
  font-size: 36px;
}
.onepager-text20 {
  font-size: 24px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
}
.onepager-kontakt {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 400px;
  align-items: flex-start;
  margin-bottom: 0px;
  justify-content: center;
}
.onepager-container10 {
  flex: 0 0 auto;
  width: 95%;
  height: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.onepager-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 40%;
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.onepager-text21 {
  font-size: 48px;
  align-self: center;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-threeunits);
}
.onepager-text24 {
  font-size: 40px;
  margin-top: var(--dl-space-space-threeunits);
  text-align: center;
}
.onepager-form {
  width: 624px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: center;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.6);
  align-items: center;
  margin-right: 0px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(62, 87, 168, 0.1);
}
.onepager-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.onepager-input {
  width: 100%;
  height: 48px;
  outline: none;
  transition: 0.2s;
  border-width: 0px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}
.onepager-input:focus {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
}
.onepager-input:hover {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
}
.onepager-textinput {
  width: 100%;
  height: 48px;
  outline: none;
  transition: 0.2s;
  border-width: 0px;
  margin-right: 0px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}
.onepager-textinput:focus {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
}
.onepager-textinput:hover {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
}
.onepager-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: 48px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: center;
}
.onepager-input1 {
  width: 100%;
  height: 48px;
  outline: none;
  transition: 0.2s;
  border-width: 0px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}
.onepager-input1:focus {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
}
.onepager-input1:hover {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
}
.onepager-textinput1 {
  width: 100%;
  height: 48px;
  outline: none;
  transition: 0.2s;
  border-width: 0px;
  margin-right: 0px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}
.onepager-textinput1:focus {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
}
.onepager-textinput1:hover {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
}
.onepager-textarea {
  width: 100%;
  height: var(--dl-size-size-large);
  outline: none;
  margin-top: var(--dl-space-space-unit);
  transition: 0.2s;
  padding-top: 0.7rem;
  border-width: 0px;
  padding-left: 0.9rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}
.onepager-textarea:focus {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
}
.onepager-textarea:hover {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
}
.onepager-input2 {
  height: 48px;
  outline: none;
  padding: 11px;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  transition: 0.2s;
  border-width: 0px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}
.onepager-input2:focus {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
  border-color: #000000;
  background-color: #ffffff;
}
.onepager-input2:hover {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
}
.onepager-button {
  width: var(--dl-size-size-xlarge);
  height: var(--dl-size-size-small);
  outline: none;
  margin-top: var(--dl-space-space-unit);
  transition: 0.15s;
  border-width: 0px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.8);
}
.onepager-button:hover {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
}
.onepager-button:active {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.6);
  background-color: #a5de3c;
}
.onepager-jalus {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  margin-top: 120px;
  align-items: center;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: var(--dl-space-space-fourunits);
  margin-bottom: 0px;
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
}
.onepager-text28 {
  color: rgb(65, 65, 65);
  text-align: center;
}
.onepager-text29 {
  line-height: 1.8;
}
.onepager-text31 {
  line-height: 1.8;
}
.onepager-text33 {
  line-height: 1.8;
}
.onepager-text35 {
  line-height: 1.8;
}
.onepager-text38 {
  color: rgb(65, 65, 65);
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
}
@media(max-width: 1600px) {
  .onepager-header {
    width: 95%;
  }
  .onepager-text {
    top: 35%;
    width: 650px;
    min-width: 0px;
  }
  .onepager-container04 {
    width: 235px;
    height: auto;
    align-items: center;
    justify-content: space-between;
  }
  .onepager-link {
    top: 50%;
    position: static;
  }
  .onepager-link1 {
    position: static;
  }
  .onepager-image2 {
    right: 10%;
    margin-left: var(--dl-space-space-threeunits);
  }
  .onepager-text10 {
    font-size: 48px;
  }
  .onepager-text11 {
    font-size: 48px;
  }
  .onepager-container11 {
    justify-content: space-between;
  }
  .onepager-text21 {
    font-size: 48px;
  }
  .onepager-text24 {
    font-size: 40px;
  }
  .onepager-form {
    width: 624px;
    margin-right: 0px;
  }
}
@media(max-width: 1200px) {
  .onepager-text {
    width: auto;
    font-size: 44px;
  }
  .onepager-image2 {
    right: 5%;
    width: auto;
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .onepager-text10 {
    font-size: 48px;
  }
  .onepager-text11 {
    font-size: 48px;
  }
  .onepager-text21 {
    font-size: 48px;
    margin-left: var(--dl-space-space-threeunits);
  }
  .onepager-text22 {
    font-size: 48px;
  }
  .onepager-text24 {
    font-size: 40px;
  }
}
@media(max-width: 991px) {
  .onepager-text {
    width: auto;
    font-size: 39px;
  }
  .onepager-image2 {
    right: 2%;
    width: auto;
    height: 220px;
  }
  .onepager-text10 {
    font-size: 39px;
  }
  .onepager-text11 {
    font-size: 40px;
  }
  .onepager-printimine {
    height: 200px;
  }
  .onepager-container07 {
    width: 100%;
    max-width: 500px;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
  }
  .onepager-text13 {
    font-size: 36px;
  }
  .onepager-text14 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .onepager-mudelleerimine {
    height: 200px;
  }
  .onepager-container08 {
    width: 100%;
    max-width: 500px;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
  }
  .onepager-text16 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .onepager-prototpimine {
    height: 200px;
  }
  .onepager-container09 {
    width: 100%;
    max-width: 500px;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
  }
  .onepager-text20 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .onepager-container10 {
    flex-direction: column;
  }
  .onepager-text21 {
    text-align: center;
  }
  .onepager-text24 {
    display: none;
  }
  .onepager-form {
    width: 624px;
    margin-top: var(--dl-space-space-fourunits);
    margin-right: 0px;
  }
}
@media(max-width: 767px) {
  .onepager-header {
    width: 90%;
    height: 70px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .onepager-nav {
    display: none;
  }
  .onepager-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .onepager-mobile-menu {
    top: 0px;
    height: var(--dl-size-size-xxlarge);
    display: none;
    z-index: 2;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.6);
    padding-top: 27px;
    padding-left: 39px;
    border-radius: 10px;
    padding-right: 32px;
    padding-bottom: 27px;
    background-color: rgba(255, 255, 255, 0.18);
  }
  .onepager-nav1 {
    top: 120px;
    left: 0px;
    right: 0px;
    position: absolute;
    margin-top: auto;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    justify-content: flex-start;
  }
  .onepager-image1 {
    width: auto;
    height: 16px;
    align-self: flex-start;
  }
  .onepager-container01 {
    top: 0px;
    right: 32px;
    width: auto;
    position: absolute;
    align-self: flex-end;
    margin-top: 28px;
    margin-bottom: 0px;
    justify-content: flex-end;
  }
  .onepager-icon2 {
    align-self: center;
  }
  .onepager-container02 {
    width: 90%;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .onepager-container03 {
    position: static;
    margin-top: 20vh;
    align-items: center;
    margin-left: 0px;
  }
  .onepager-text {
    font-size: 32px;
  }
  .onepager-image2 {
    position: static;
    margin-top: 80px;
    margin-left: 0px;
  }
  .onepager-container06 {
    width: 90%;
  }
  .onepager-text10 {
    font-size: 39px;
  }
  .onepager-text11 {
    font-size: 40px;
  }
  .onepager-printimine {
    height: auto;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    justify-content: center;
  }
  .onepager-image3 {
    display: none;
  }
  .onepager-container07 {
    width: 100%;
    max-width: 400px;
    align-items: center;
    padding-left: 0px;
  }
  .onepager-text14 {
    margin-top: var(--dl-space-space-unit);
  }
  .onepager-mudelleerimine {
    height: auto;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    justify-content: center;
  }
  .onepager-image4 {
    display: none;
  }
  .onepager-container08 {
    width: 100%;
    max-width: 400px;
    align-items: center;
    padding-left: 0px;
  }
  .onepager-text16 {
    margin-top: var(--dl-space-space-unit);
  }
  .onepager-prototpimine {
    height: auto;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    justify-content: center;
  }
  .onepager-image5 {
    display: none;
  }
  .onepager-container09 {
    width: 100%;
    max-width: 400px;
    align-items: center;
    padding-left: 0px;
  }
  .onepager-text17 {
    font-size: 36px;
  }
  .onepager-text18 {
    font-size: 32px;
  }
  .onepager-text20 {
    margin-top: var(--dl-space-space-unit);
  }
  .onepager-container10 {
    width: 90%;
  }
  .onepager-text21 {
    font-size: 48px;
  }
  .onepager-text22 {
    font-size: 40px;
  }
  .onepager-form {
    width: 100%;
    max-width: 624px;
  }
}
@media(max-width: 479px) {
  .onepager-header {
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: 0px;
  }
  .onepager-mobile-menu {
    display: none;
    padding-top: 27px;
    padding-left: 39px;
    padding-right: 32px;
    padding-bottom: 27px;
  }
  .onepager-container03 {
    height: 160px;
    margin-top: 20vh;
  }
  .onepager-text {
    font-size: 28px;
  }
  .onepager-image2 {
    margin-top: 60px;
  }
  .onepager-text10 {
    font-size: 28px;
  }
  .onepager-text11 {
    font-size: 40px;
  }
  .onepager-printimine {
    padding: var(--dl-space-space-twounits);
  }
  .onepager-text13 {
    font-size: 32px;
  }
  .onepager-mudelleerimine {
    padding: var(--dl-space-space-twounits);
  }
  .onepager-text15 {
    font-size: 32px;
  }
  .onepager-prototpimine {
    padding: var(--dl-space-space-twounits);
  }
  .onepager-text21 {
    font-size: 48px;
  }
  .onepager-text22 {
    font-size: 40px;
  }
  .onepager-text28 {
    color: rgb(65, 65, 65);
  }
  .onepager-text29 {
    line-height: 1.8;
  }
  .onepager-text30 {
    line-height: 1.8;
  }
  .onepager-text31 {
    line-height: 1.8;
  }
  .onepager-text32 {
    line-height: 1.8;
  }
  .onepager-text33 {
    line-height: 1.8;
  }
  .onepager-text34 {
    line-height: 1.8;
  }
  .onepager-text35 {
    line-height: 1.8;
  }
}
